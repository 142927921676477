import createSagaMiddleware from '@redux-saga/core'
import React from 'react'
import { connect, Provider } from 'react-redux'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { localStorage, sessionStorage } from '../utils/storage'
import { init } from './actions/global'
import * as altaVehiculo from './reducers/altaVehiculoReducer'
import * as appSetup from './reducers/appSetupReducer'
import * as cesta from './reducers/cestaReducer'
import { CestaOrigin, createCesta } from './reducers/cestaReducer'
import * as citaPrevia from './reducers/citaPreviaReducer'
import * as menu from './reducers/menuReducer'
import * as revision from './reducers/revisionReducer'
import * as routeReducer from './reducers/routeReducer'
import * as search from './reducers/searchReducer'
import * as selector from './reducers/selectorReducer'
import * as searchMoto from './reducers/searchMotoReducer'
import * as selectorMoto from './reducers/selectorMotoReducer'
import * as user from './reducers/userReducer'
import * as searchCamaraMoto from './reducers/searchCamaraMotoReducer'
import * as areaPrivada from './reducers/areaPrivadaReducer'
import * as clientVehicles from './reducers/clientVehiclesReducer'
import * as aireAcondicionado from './reducers/aireAcondicionadoReducer'
import * as revisionMoto from '../../moto/revisiones/store/matricula/revisionMotoMatriculaReducer'
import * as revisionMotoMarcas from '../../moto/revisiones/store/marcas/revisionMotoMarcasReducer'
import * as revisionMotoModelos from '../../moto/revisiones/store/modelos/revisionMotoModelosReducer'
import * as revisionMotoLead from '../../moto/revisiones/store/lead/revisionMotoLeadReducer'
import * as catalogoList from '../../shared/catalogo/store/list/catalogoListReducer'
import * as catalogoFilterValues from '../../shared/catalogo/store/filter/catalogoFilterValuesReducer'

import sagas from './sagas/index'
import { isCestaExternal } from '../utils/cestaLogic'
import { initialGlobalCatalogoState } from '../../shared/catalogo/store/list/catalogoListReducer'
import {
  CatalogoFilterValuesStateType,
  initialGlobalCatalogoFilterValuesState,
} from '../../shared/catalogo/store/filter/catalogoFilterValuesReducer'

const loadCestaStateFromStorage = (): cesta.CestaState => {
  if (isCestaExternal()) {
    return { ...createCesta(), origin: CestaOrigin.External }
  }

  const cestaID = localStorage.getItem(cesta.key)
  if (!cestaID) {
    return cesta.createCesta()
  }
  try {
    const data = sessionStorage.getItem(cesta.key)
    if (!data) {
      return { ...cesta.initialState, cestaID, status: 'unloaded' }
    }
    const cestaState = JSON.parse(data)
    if (
      typeof cestaState !== 'object' ||
      typeof cestaState.cestaID !== 'string' ||
      cestaState.cestaID !== cestaID
    ) {
      return { ...cesta.initialState, cestaID, status: 'unloaded' }
    }
    return { ...cestaState, status: 'dirty' } as cesta.CestaState
  } catch (e) {
    return { ...cesta.initialState, cestaID, loading: true, status: 'unloaded' }
  }
}
export const loadedCestaState = loadCestaStateFromStorage()

const storeMapping = {
  [user.key]: localStorage,
  [cesta.key]: sessionStorage,
  [selector.key]: sessionStorage,
  [selectorMoto.key]: sessionStorage,
  [altaVehiculo.key]: sessionStorage,
  [revision.key]: sessionStorage,
  [citaPrevia.key]: sessionStorage,
  [menu.key]: sessionStorage,
  [appSetup.key]: sessionStorage,
  [areaPrivada.key]: sessionStorage,
  [clientVehicles.key]: sessionStorage,
  [aireAcondicionado.key]: sessionStorage,
  [revisionMoto.key]: sessionStorage,
  [revisionMotoMarcas.key]: sessionStorage,
  [revisionMotoModelos.key]: sessionStorage,
  [revisionMotoLead.key]: sessionStorage,
  [catalogoList.key]: sessionStorage,
  [catalogoFilterValues.key]: sessionStorage,
}
const storageMiddleware = (store) => (next) => (action) => {
  const oldState = store.getState()
  const result = next(action)
  const newState = store.getState()
  Object.keys(storeMapping).forEach((k) => {
    if (oldState[k] !== newState[k]) {
      setTimeout(
        () => storeMapping[k].setItem(k, JSON.stringify(newState[k])),
        10
      )
    }
  })
  return result
}

const hasMatchingKeys = (a: object, b: object) => {
  const ak = Object.keys(a)
  const bk = Object.keys(b)
  if (ak.length !== bk.length) {
    return false
  }
  ak.forEach((k) => {
    if (!(k in b)) {
      return false
    }
  })
  return true
}

const ensureNotLoading = (obj: { loading?: boolean; [k: string]: any }) => {
  if ('loading' in obj) {
    obj.loading = false
  }
  Object.keys(obj).forEach((k) => {
    if (
      typeof obj[k] === 'object' &&
      !Array.isArray(obj[k]) &&
      obj[k] !== null
    ) {
      ensureNotLoading(obj[k])
    }
  })
}

const loadStoredInitialState = ({
  key,
  version,
  initialState,
}: {
  key: string
  version?: number
  initialState: any
}) => {
  try {
    const data = storeMapping[key].getItem(key)
    if (!data) {
      return initialState
    }
    const obj = JSON.parse(data)
    if (typeof obj._version === 'undefined' || obj._version !== version) {
      return initialState
    }
    if (!hasMatchingKeys(obj, initialState)) {
      console.warn('Keys for', key, ' do not match. Dropping stored state.', {
        stored: Object.keys(obj),
        pristine: Object.keys(initialState),
      })
      return initialState
    }
    ensureNotLoading(obj)
    return obj
  } catch (e) {
    console.warn('Invalid stored info', key, e)
    return initialState
  }
}

export interface IAppState {
  [selector.key]: selector.SelectorState
  [user.key]: user.UserState
  [cesta.key]: cesta.CestaState
  [altaVehiculo.key]: altaVehiculo.AltaVehiculoState
  [revision.key]: revision.RevisionState
  [search.key]: search.SearchState
  [selectorMoto.key]: selectorMoto.SelectorMotoState
  [searchMoto.key]: searchMoto.SearchMotoState
  [menu.key]: menu.MenuState
  [citaPrevia.key]: citaPrevia.CitaPreviaState
  [appSetup.key]: appSetup.AppSetupState
  [searchCamaraMoto.key]: searchMoto.SearchMotoState
  [areaPrivada.key]: areaPrivada.AreaPrivadaState
  [clientVehicles.key]: clientVehicles.ClientVehiclesState
  [aireAcondicionado.key]: aireAcondicionado.AireAcondicionadoState
  [revisionMoto.key]: revisionMoto.RevisionDatosState
  [revisionMotoMarcas.key]: revisionMotoMarcas.RevisionMarcasState
  [revisionMotoModelos.key]: revisionMotoModelos.RevisionModelosState
  [revisionMotoLead.key]: revisionMotoLead.RevisionLeadState
  [catalogoList.key]: catalogoList.CatalogoListStateType
  [catalogoFilterValues.key]: catalogoFilterValues.CatalogoFilterValuesStateType
}

type IAppContext = {
  selectorState: selector.SelectorState
  cestaState: cesta.CestaState
  userInfo: user.UserState
  altaVehiculoState: altaVehiculo.AltaVehiculoState
  revisionState: revision.RevisionState
  searchState: search.SearchState
  selectorMotoState: selectorMoto.SelectorMotoState
  searchMotoState: searchMoto.SearchMotoState
  searchCamaraMotoState: searchCamaraMoto.SearchCamaraMotoState
  menuState: menu.MenuState
  appSetupState: appSetup.AppSetupState
  citaPreviaState: citaPrevia.CitaPreviaState
  areaPrivadaState: areaPrivada.AreaPrivadaState
  clientVehiclesState: clientVehicles.ClientVehiclesState
  aireAcondicionadoState: aireAcondicionado.AireAcondicionadoState
  revisionMotoState: revisionMoto.RevisionDatosState
  revisionMotoMarcasState: revisionMotoMarcas.RevisionMarcasState
  revisionMotoModelosState: revisionMotoModelos.RevisionModelosState
  revisionMotoLeadState: revisionMotoLead.RevisionLeadState
  dispatch: (action: object) => void
  formDispatch: (action: object) => void
  userDispatch: (action: object) => void
  cestaDispatch: (action: object) => void
  altaVehiculoDispatch: (action: object) => void
  revisionDispatch: (action: object) => void
  revisionMotoDispatch: (action: object) => void
  revisionMotoMarcasDispatch: (action: object) => void
  revisionMotoModelosDispatch: (action: object) => void
  revisionMotoLeadDispatch: (action: object) => void
  citaPreviaDispatch: (action: object) => void
  appSetupDispatch: (action: object) => void
  catalogoListState: catalogoList.CatalogoListStateType
  catalogoFilterValuesState: catalogoFilterValues.CatalogoFilterValuesStateType
}

const AppContext = React.createContext({} as IAppContext)
export default AppContext

const rootReducer = combineReducers({
  [user.key]: user.reducer,
  [cesta.key]: cesta.reducer,
  [revision.key]: revision.reducer,
  [selector.key]: selector.reducer,
  [altaVehiculo.key]: altaVehiculo.reducer,
  [search.key]: search.reducer,
  [selectorMoto.key]: selectorMoto.reducer,
  [searchMoto.key]: searchMoto.reducer,
  [searchCamaraMoto.key]: searchCamaraMoto.reducer,
  [menu.key]: menu.reducer,
  [routeReducer.key]: routeReducer.reducer,
  [citaPrevia.key]: citaPrevia.reducer,
  [appSetup.key]: appSetup.reducer,
  [areaPrivada.key]: areaPrivada.reducer,
  [clientVehicles.key]: clientVehicles.reducer,
  [aireAcondicionado.key]: aireAcondicionado.reducer,
  [revisionMoto.key]: revisionMoto.reducer,
  [revisionMotoMarcas.key]: revisionMotoMarcas.revisionMotoMarcasReducer,
  [revisionMotoModelos.key]: revisionMotoModelos.revisionMotoModelosReducer,
  [revisionMotoLead.key]: revisionMotoLead.revisionMotoLeadReducer,
  [catalogoList.key]: catalogoList.catalogoListReducer,
  [catalogoFilterValues.key]: catalogoFilterValues.catalogoFilterValuesReducer,
})

const loadUserInfoInitialState = () => {
  const data = loadStoredInitialState(user)
  if (data.loading) {
    return user.initialState
  }
  return data
}

const initialState = {
  [selector.key]: loadStoredInitialState(selector),
  [user.key]: loadUserInfoInitialState(),
  [cesta.key]: loadCestaStateFromStorage(),
  [altaVehiculo.key]: loadStoredInitialState(altaVehiculo),
  [revision.key]: loadStoredInitialState(revision),
  [citaPrevia.key]: loadStoredInitialState(citaPrevia),
  [search.key]: search.initialState,
  [selectorMoto.key]: loadStoredInitialState(selectorMoto),
  [searchMoto.key]: searchMoto.initialState,
  [searchCamaraMoto.key]: searchCamaraMoto.initialState,
  [menu.key]: loadStoredInitialState(menu),
  [appSetup.key]: loadStoredInitialState(appSetup),
  [routeReducer.key]: routeReducer.initialState,
  [areaPrivada.key]: loadStoredInitialState(areaPrivada),
  [clientVehicles.key]: clientVehicles.initialState,
  [aireAcondicionado.key]: aireAcondicionado.initialState,
  [revisionMoto.key]: revisionMoto.initialState,
  [revisionMotoMarcas.key]: revisionMotoMarcas.initialState,
  [revisionMotoModelos.key]: revisionMotoModelos.initialState,
  [revisionMotoLead.key]: revisionMotoLead.initialState,
  [catalogoList.key]: catalogoList.initialGlobalCatalogoState,
  [catalogoFilterValues.key]:
    catalogoFilterValues.initialGlobalCatalogoFilterValuesState,
}
const composeEnhancers = composeWithDevTools({
  trace: process.env.NODE_ENV === 'development',
})
const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, storageMiddleware, sagaMiddleware))
)
// Skip sagas during server rendering
if (typeof window !== 'undefined') {
  sagaMiddleware.run(sagas)
}
store.dispatch(init())

const InternalStoreProvider = ({
  dispatch,
  selectorState,
  userInfo,
  cestaState,
  altaVehiculoState,
  revisionState,
  citaPreviaState,
  appSetupState,
  searchState,
  selectorMotoState,
  searchMotoState,
  searchCamaraMotoState,
  menuState,
  areaPrivadaState,
  clientVehiclesState,
  aireAcondicionadoState,
  revisionMotoState,
  revisionMotoMarcasState,
  revisionMotoModelosState,
  revisionMotoLeadState,
  catalogoListState,
  catalogoFilterValuesState,
  children,
}) => {
  return (
    <AppContext.Provider
      value={{
        selectorState,
        cestaState,
        userInfo,
        altaVehiculoState,
        revisionState,
        searchState,
        selectorMotoState,
        searchMotoState,
        searchCamaraMotoState,
        citaPreviaState,
        menuState,
        appSetupState,
        areaPrivadaState,
        clientVehiclesState,
        aireAcondicionadoState,
        revisionMotoState,
        revisionMotoMarcasState,
        revisionMotoModelosState,
        revisionMotoLeadState,
        dispatch,
        citaPreviaDispatch: dispatch,
        formDispatch: dispatch,
        userDispatch: dispatch,
        cestaDispatch: dispatch,
        altaVehiculoDispatch: dispatch,
        revisionDispatch: dispatch,
        appSetupDispatch: dispatch,
        revisionMotoDispatch: dispatch,
        revisionMotoMarcasDispatch: dispatch,
        revisionMotoModelosDispatch: dispatch,
        revisionMotoLeadDispatch: dispatch,
        catalogoListState,
        catalogoFilterValuesState,
      }}>
      {children}
    </AppContext.Provider>
  )
}

const AppProvider = connect((state) => ({
  selectorState: state[selector.key],
  userInfo: state[user.key],
  cestaState: state[cesta.key],
  altaVehiculoState: state[altaVehiculo.key],
  revisionState: state[revision.key],
  citaPreviaState: state[citaPrevia.key],
  searchState: state[search.key],
  selectorMotoState: state[selectorMoto.key],
  searchMotoState: state[searchMoto.key],
  searchCamaraMotoState: state[searchCamaraMoto.key],
  areaPrivadaState: state[areaPrivada.key],
  menuState: state[menu.key],
  appSetupState: state[appSetup.key],
  clientVehiclesState: state[clientVehicles.key],
  aireAcondicionadoState: state[aireAcondicionado.key],
  revisionMotoState: state[revisionMoto.key],
  revisionMotoMarcasState: state[revisionMotoMarcas.key],
  revisionMotoModelosState: state[revisionMotoModelos.key],
  revisionMotoLeadState: state[revisionMotoLead.key],
  rev: state[revisionMoto.key],
  catalogoListState: state[catalogoList.key],
  catalogoFilterValuesState: state[catalogoFilterValues.key],
}))(InternalStoreProvider)

export const StoreProvider = ({ children }) => (
  <Provider store={store}>
    <AppProvider>{children}</AppProvider>
  </Provider>
)
