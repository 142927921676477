import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../components/Layout'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { t } from '../../i18n'
import route from '../../utils/route'
import ComprarButton from '../../../shared/catalogo/producto/ficha/ComprarButton'
import RequestButton from '../../../shared/catalogo/producto/ficha/RequestButton'
import { productoActivo } from '../../components/FichasNeumaticos/utils'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'

const FichaAceitePage = ({
  data: { aceiteInfo, seoData },
}: PageProps<Queries.FichaAceitePageQuery>) => {
  useSelectedMenu(MenuPrincipal.COCHE)
  const [showModal, setShowModal] = useState(false)
  const [selectedProducto, setSelectedProducto] = useState(
    null as CatalogoProductInfo | null
  )
  const isActive = productoActivo(aceiteInfo.producto)

  const CTAButton = isActive ? (
    <ComprarButton
      productInfo={{
        ...aceiteInfo,
        specifications: [],
      }}
    />
  ) : (
    <RequestButton
      productInfo={{
        ...aceiteInfo,
        specifications: [],
      }}
      openModal={setShowModal}
      selectProduct={setSelectedProducto}></RequestButton>
  )

  return (
    <Layout
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        { display: t('bread_crumb.coche'), link: route('coche.index') },
        {
          display: t('bread_crumb.aceites'),
          link: route('aceites.index'),
        },
      ]}
      selector={false}>
      <CatalogoFicha
        productInfo={{
          ...aceiteInfo,
          specifications: [
            {
              label: t('aceites.list.elemento.capacidad'),
              value: `${aceiteInfo.cantidad}`,
            },
            {
              label: t('aceites.list.elemento.viscosidad'),
              value: aceiteInfo.viscosidad,
            },
          ],
          tipo: 'aceite',
        }}
        cta={CTAButton}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query FichaAceitePage($idSiteProductosAceites: Int!, $url: String!) {
    aceiteInfo: aceite(
      id_site_productos_aceites: { eq: $idSiteProductosAceites }
    ) {
      ...aceiteInfo
      ...productoAceiteInfo
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
export default FichaAceitePage
